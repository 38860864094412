import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';

function Team() {
  const theme = useTheme();
  const { isSmUp } = useBreakpoints();

  const TeamMember: React.FC<{image: string, title: string, subtitle: string, color: string}> = ({image, title, subtitle, color}) => {

    return (
      <div style={{
        borderRadius: theme.spacing(1),
        background: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(18),
        padding: theme.spacing(1.5, 1),
        margin: theme.spacing(2, isSmUp ? 1 : .5, 0, isSmUp ? 1 : .5)
      }}>
        <div style={{
          borderRadius: theme.spacing(1),
          background: color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
        }}>
          <img src={`${process.env.PUBLIC_URL}/${image}`} alt="chess" style={{height: theme.spacing(20)}}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: theme.spacing(1, .7, 0, .7)}}>
          <Typography style={{color: 'white', fontSize: theme.spacing(2.2), fontWeight: '600'}}>{title}</Typography>
          <Typography style={{color: theme.palette.info.main, fontSize: theme.spacing(1.7), fontWeight: '500'}}>{subtitle}</Typography>
        </div>
      </div>
    )
  }

  const TeamMembers = [
    { title: 'Tartar', subtitle: 'Blockchain Developer', image: 'backend.png', color: '#fec2e6'},
    { title: 'Gainz', subtitle: 'Blockchain Developer', image: 'blockchain.png', color: '#c2fefe'},
    { title: 'Friend', subtitle: 'Front-end Developer', image: 'frontend.png', color: '#cac2fe'},
    { title: 'ErikYdze', subtitle: 'Designer', image: 'designer.png', color: '#fdfec2'},
  ]

  return (
    <Container maxWidth={'lg'}>
      <div style={{paddingBottom: theme.spacing(5), overflow: 'hidden'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(3)}}>
          <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Meet Our Team</Typography>
          <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}>Our talented team shaping the future of gaming</Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap'}}>
          { TeamMembers.map((member) =>
            <TeamMember
              image={member.image}
              title={member.title}
              subtitle={member.subtitle}
              color={member.color}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Team;

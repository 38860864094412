import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useBreakpoints = () => {
  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const isXlDown = useMediaQuery(theme.breakpoints.up('xl'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return {
    isXlUp,
    isXlDown,
    isLgUp,
    isLgDown,
    isMdUp,
    isMdDown,
    isSmUp,
    isSmDown,
    isXsUp,
    isXsDown,
    isXs,
  };
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import Landing from './routes/Landing';
import Navbar from './components/global/navbar'
import Footer from './components/global/footer'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import theme from './theme';
import './index.css'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Navbar/>
        <RouterProvider router={router} />
      <Footer/>
    </ThemeProvider>
  </React.StrictMode>
);
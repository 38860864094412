import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

function Partners() {
  const theme = useTheme();

  const Partners = [
    { image: 'GameStarter.png', alt: 'GameStarter'},
    { image: 'raydium.png', alt: 'Raydium'},
  ]

  return (
    <Container maxWidth={'lg'}>
      <div style={{paddingBottom: theme.spacing(10), overflow: 'hidden', paddingTop: theme.spacing(5)}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(3)}}>
          <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Our Partners</Typography>
          <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}>Powerful partnerships, driving innovation to new heights</Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
          {
            Partners.map((partner) =>
              <img src={`${process.env.PUBLIC_URL}/${partner.image}`} alt={partner.alt} style={{height: theme.spacing(6), margin: theme.spacing(2, 3)}}/>
            )
          }
        </div>
      </div>
    </Container>
  );
};

export default Partners;

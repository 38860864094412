import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';
import Chart from 'react-apexcharts';
import './chart.css';

function Tokenomics() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  const options = {
    labels: ['Liquidity Pool: 10%', 'Ecosystem Development: 23%', 'Game Developers incentive: 7%', 'Team and Founders: 5%', 'Marketing and Partnerships: 30%', 'Staking Rewards: 23%', 'Community Airdrops/Bounties: 2%'],
    stroke: { show: true, colors: [theme.palette.primary.main] },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        return `<div style="background-color: ${theme.palette.primary.light}; color: #fff; padding: 10px; border-radius: 5px;">
                <strong>${w.globals.labels[seriesIndex]}</strong>
              </div>`;
      }
    },
    legend: {
      position: isMdUp ? 'right' as 'right' : 'bottom' as 'bottom', // Position the legend on the right side
      fontSize: '16px',
      labels: {
        colors: 'gray',
        useSeriesColors: false
      },
      markers: {
        width: 10,
        height: 10,
      },
      itemMargin: {
        vertical: 5
      }
    },
    colors: ['#BF0273', '#D12A8A', '#E352A1', '#F47AB8', '#FFA1CF', '#FFB9D5', '#FFA3DA'],
  };
  const series = [10, 23, 7, 5, 30, 23, 2];

  return (
    <Container maxWidth={'lg'}>
      <div style={{paddingBottom: theme.spacing(5), paddingTop: theme.spacing(5), overflow: 'hidden'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(5)}}>
          <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Tokenomics</Typography>
          <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}><strong style={{color: 'white'}}>Stratogen's</strong> unique financial setup</Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: isMdUp ? 'row' : 'column'}}>
          <Chart
            options={options}
            series={series}
            type="pie"
            width={isMdUp ? 605 : '100%'}
            height={isMdUp ? 420 : 600}
            className="custom-pie-chart"
          />
        </div>
      </div>
    </Container>
  );
};

export default Tokenomics;

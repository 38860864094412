import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';

function TitleSection() {
  const theme = useTheme();
  const { isMdUp, isSmUp } = useBreakpoints();

  return (
      <Container maxWidth={'lg'}>
        <div style={{display: 'flex', flexDirection: isMdUp ? 'row' : 'column-reverse', alignItems: isMdUp ? 'start' : 'center', justifyContent: 'space-between', padding: theme.spacing(10, 0, 3, 0)}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', padding: isMdUp ? theme.spacing(2, 0, 0, 0) : theme.spacing(0, 0, 5 ,0), width: isMdUp ? '55%' : '100%'}}>
            <Typography variant={'h1'} style={{color: 'white', fontSize: theme.spacing(isMdUp ? 6.5 : 5), fontWeight: '600'}}>Strategy Unleashed with Web3 Technology</Typography>
            <Typography style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(3)}}>Welcome to <strong style={{color: 'white'}}>Stratogen</strong>, where blockchain redefines MMORTS gaming. Craft game worlds, trade unique NFTs, and join a vibrant community for an immersive gaming experience.</Typography>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: theme.spacing(2), flexWrap: 'wrap'}}>
              <a target={'_blank'} rel="noreferrer"  href={'https://t.me/stratogenchat'}>
                <Button startIcon={<TelegramIcon/>} color={'secondary'} variant="contained" sx={{textTransform: 'none', borderRadius: theme.spacing(1), marginRight: theme.spacing(1), marginTop: theme.spacing(1), fontWeight: '600'}}>Join Telegram</Button>
              </a>
              <a target={'_blank'} rel="noreferrer"  href={'https://twitter.com/StratogenTech'}>
                <Button color='info' startIcon={<XIcon/>} variant="contained" sx={{textTransform: 'none', borderRadius: theme.spacing(1), background: 'white', marginRight: theme.spacing(1), fontWeight: '600', marginTop: theme.spacing(1)}}>Join X</Button>
              </a>
              <a target={'_blank'} rel="noreferrer"  href={'https://docs.stratogen.tech/'}>
                <Button endIcon={<ArrowForwardIcon/>} sx={{textTransform: 'none', marginLeft: theme.spacing(isSmUp ? 0 : -1), borderRadius: theme.spacing(1), color: 'white', fontWeight: '500', marginTop: theme.spacing(1)}}>Whitepaper</Button>
              </a>
            </div>
          </div>
          <div style={{ position: 'relative', marginRight: isMdUp ? 0  : theme.spacing(-10)}}>
            <img src={`${process.env.PUBLIC_URL}/tower.png`} alt="tower" style={{ position: 'absolute', zIndex: 1, marginLeft: theme.spacing(-8), marginTop: theme.spacing(-4), height: theme.spacing(isMdUp ? 30 : 22)}}/>
            <img src={`${process.env.PUBLIC_URL}/horse.png`} alt="horse" style={{ position: 'relative', zIndex: 2, height: theme.spacing(isMdUp ? 45 : 30)}}/>
          </div>
        </div>
      </Container>
  );
}

export default TitleSection;

import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';

function Games() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  return (
      <Container maxWidth={'lg'} id='games'>
        <div style={{paddingBottom: theme.spacing(5), overflow: 'hidden'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(5)}}>
            <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Games</Typography>
            <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}>
              Explore the incredible titles <strong style={{color: 'white'}}>Stratogen</strong> has powered
            </Typography>
          </div>
          <div style={{borderRadius: theme.spacing(1), background: theme.palette.primary.light, display: 'flex', flexDirection: isMdUp ? 'row' : 'column'}}>
            <img
              src={`${process.env.PUBLIC_URL}/terranora.png`}
              alt="terranora war"
              style={{
                height: isMdUp ? theme.spacing(35) : 'auto',
                width: isMdUp ? theme.spacing(61) : '100%',
                borderTopLeftRadius: theme.spacing(1),
                borderBottomLeftRadius: theme.spacing(isMdUp ? 1 : 0),
                borderTopRightRadius: theme.spacing(isMdUp ? 0 : 1),
              }}
            />
            <div style={{width: isMdUp ? '50%' : 'auto', display: 'flex', flexDirection: 'column', padding: theme.spacing(3), justifyContent: 'space-between'}}>
              <div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Typography variant='h3' style={{color: 'white', fontSize: theme.spacing(3.5), fontWeight: '600', marginRight: theme.spacing(2)}}>TerraNora</Typography>
                  <div style={{border: '1px solid', borderRadius: theme.spacing(.5), padding: theme.spacing(.5, 1), borderColor: theme.palette.secondary.main}}>
                    <Typography style={{color: theme.palette.secondary.main, fontSize: theme.spacing(1.2), fontWeight: '500'}}>COMING SOON!</Typography>
                  </div>
                </div>
                <Typography style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(3)}}>Dive into TerraNora, a Web3 gaming experience powered by Stratogen: Command your legacy through strategy in a realm where magic and might converge. Build, ally, and conquer in a world where every choice propels you toward victory.
                </Typography>
              </div>
              <a href={'https://terranora.io/'}>
                <Button
                  color='info'
                  endIcon={<ArrowForwardIcon/>}
                  variant="contained"
                  sx={{textTransform: 'none', borderRadius: theme.spacing(1), background: 'white', marginRight: theme.spacing(1), width: theme.spacing(10), marginTop: theme.spacing(2), fontWeight: '600'}}>
                  Play
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Container>
  );
};

export default Games;

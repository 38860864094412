import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Container, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import {useBreakpoints} from '../../../utils/breakpoints';

function Resources() {
  const theme = useTheme();
  const { isMdUp, isSmUp } = useBreakpoints();

  const Resource: React.FC<{image: React.ReactNode, link: string, title: string, subtitle: string}> = ({image, link, title, subtitle}) => {

    return (
      <div style={{borderRadius: theme.spacing(1), background: theme.palette.primary.light, display: 'flex', flexDirection: isSmUp ? 'row' : 'column', width: isMdUp ? '45%' : 'auto', padding: theme.spacing(2), marginTop: theme.spacing(isMdUp ? 0 : 2)}}>
        <div style={{
          borderRadius: theme.spacing(1),
          background: theme.palette.primary.contrastText,
          display: 'flex',
          width: isSmUp ? '40%' : 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: theme.spacing(isSmUp ? 2 : 0), padding: theme.spacing(1)
        }}>
          {image}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: theme.spacing(isSmUp ? 0 : 2)}}>
          <Typography variant='h3' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), fontWeight: '600'}}>{title}</Typography>
          <Typography style={{color: 'white', fontSize: theme.spacing(isSmUp ? 2.5 : 2), fontWeight: '600', marginTop: theme.spacing(isSmUp ? 0 : 1)}}>{subtitle}</Typography>
          <a href={link} target={'_blank'} rel="noreferrer"  style={{marginLeft: theme.spacing(-1), marginBottom: theme.spacing(-1)}}>
            <Button endIcon={<ArrowForwardIcon fontSize={'small'}/>} sx={{textTransform: 'none', borderRadius: theme.spacing(1), color: 'white', fontSize: theme.spacing(1.7), fontWeight: '500'}}>Learn more</Button>
          </a>
        </div>
      </div>
    )
  }

  return (
    <Container maxWidth={'lg'}>
      <div style={{paddingBottom: theme.spacing(5), overflow: 'hidden'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(5)}}>
          <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Resources</Typography>
          <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}>Our key resources for an enriched experience</Typography>
        </div>
        <div style={{display: 'flex', flexDirection: isMdUp ? 'row' : 'column', width: '100%', justifyContent: 'space-between'}}>
          <Resource
            image={
              <img src={`${process.env.PUBLIC_URL}/chess.png`} alt="chess" style={{height: theme.spacing(20)}}/>
            }
            title='Elevate your game development with Stratogen SDK'
            subtitle='Your toolkit for creating immersive blockchain-enabled games'
            link='https://docs.stratogen.tech/'
          />
          <Resource
            image={
              <img src={`${process.env.PUBLIC_URL}/sdk.png`} alt="chess" style={{height: theme.spacing(18)}}/>
            }
            title='Govern'
            subtitle='Engage in shaping the future of Stratogen ecosystem through active participation and governance'
            link='https://docs.stratogen.tech/'
          />
        </div>
      </div>
    </Container>
  );
};

export default Resources;

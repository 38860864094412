import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

function ContactUs() {
  const theme = useTheme();

  return (
    <Container maxWidth={'lg'}>
      <div style={{paddingBottom: theme.spacing(10), overflow: 'hidden', paddingTop: theme.spacing(2)}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: theme.spacing(3)}}>
          <Typography variant='h2' style={{color: 'white', fontSize: theme.spacing(4), fontWeight: '600'}}>Contact Us</Typography>
          <Typography align='center' style={{color: theme.palette.info.main, fontSize: theme.spacing(2), marginTop: theme.spacing(1)}}>Contact us via email</Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
          <a style={{color: theme.palette.secondary.main, fontSize: theme.spacing(2.4), textDecoration: 'none'}} href={'mailto:info@stratogen.tech'}>info@stratogen.tech</a>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;

import React from 'react';
import { useTheme } from '@mui/material';
import TitleSection from './Sections/TitleSection';
import ContactUs from './Sections/ContactUs';
import Games from './Sections/Games';
import Resources from './Sections/Resources';
import Team from './Sections/Team';
import Partners from './Sections/Partners';
import Tokenomics from './Sections/Tokenomics';

function Index() {
  const theme = useTheme();
  const background = {background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
};

  return (
    <div>
      <div style={background}>
        <TitleSection/>
        <Games/>
      </div>
      <div style={{...background, paddingTop: theme.spacing(8)}}>
        <Resources/>
        <Tokenomics/>
      </div>
      <div style={{...background, paddingTop: theme.spacing(8)}}>
        <Team/>
        <Partners/>
        <ContactUs/>
      </div>
    </div>
  );
}

export default Index;

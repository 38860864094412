import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import { Container, IconButton, Box, Button, Divider, Typography } from '@mui/material';
import {useTheme} from '@mui/material';
import './index.css';
import {useBreakpoints} from '../../../utils/breakpoints';
import {scrollToComponent} from '../../../utils/usefull';

function Index() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();

  const iconButtonStyle = {backgroundColor: theme.palette.primary.contrastText, color: 'white', marginRight: theme.spacing(1)};
  const buttonStyle = {color: 'white', textTransform: 'none', fontSize: theme.spacing(2.3), padding: theme.spacing(1, 0, 0, 0)};
  const listButtonStyle = {color: theme.palette.info.main, textTransform: 'none', fontSize: theme.spacing(2), width: 'auto', marginTop: theme.spacing(isMdUp ? 1 : 0), padding: 0};
  const buttonsBoxStyle = {display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: isMdUp ? 'center' : 'start', paddingLeft: theme.spacing(1), width: isMdUp ? '31%' : '45%'};

  return (
    <Box>
      <Divider/>
      <Box style={{backgroundColor: '#1e1e1f', padding: theme.spacing(5, 0, isMdUp ? 5 : 2, 0)}}>
          <Container maxWidth={'xl'} style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Box style={{display: 'flex', justifyContent: 'space-between', flexDirection: isMdUp ? 'row' : 'column', width: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'column', maxWidth: theme.spacing(55)}}>
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" height={theme.spacing(4)} width={theme.spacing(19)} style={{marginBottom: theme.spacing(2)}}/>
                <Typography color={theme.palette.info.main} style={{fontWeight: '500'}}>Power your game creation with Stratogen — the cutting-edge blockchain engine where innovative gameplay and true ownership come to life.</Typography>
              </div>
              <Box style={{display: 'flex', flexDirection: 'row', width: isMdUp ? '42%' : 'auto', alignItems: 'start', justifyContent: isMdUp ? 'space-between' : 'start', flexWrap: 'wrap', marginLeft: theme.spacing(-1)}}>
                <Box sx={buttonsBoxStyle}>
                  <Typography sx={buttonStyle}>Whitepaper</Typography>
                  <Button onClick={() => console.log('test')} sx={listButtonStyle}>
                    Disclaimer
                  </Button>
                  <Button onClick={() => console.log('test')} sx={listButtonStyle}>
                    Ecosystem
                  </Button>
                  <Button onClick={() => console.log('test')} sx={listButtonStyle}>
                    Tokenomics
                  </Button>
                  <Button onClick={() => console.log('test')} sx={listButtonStyle}>
                    Roadmaps
                  </Button>
                </Box>
                <Box sx={buttonsBoxStyle}>
                  <Typography sx={buttonStyle}>Games</Typography>
                  <Button onClick={() => scrollToComponent('games')} sx={listButtonStyle}>
                    TerraNora</Button>
                </Box>
                <Box sx={buttonsBoxStyle}>
                  <Typography sx={buttonStyle}>Exchange</Typography>
                  <a href={'https://raydium.io/'} target={'_blank'} rel="noreferrer" >
                    <Button variant='outlined' onClick={() => console.log('test')} sx={listButtonStyle}>
                      Raydium
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: theme.spacing(isMdUp ? 4 : 2), flexDirection: isMdUp ? 'row' : 'column-reverse', alignItems: isMdUp ? 'center' : 'start'}}>
              <Typography color={theme.palette.info.main} style={{fontWeight: '500', marginTop: isMdUp ? 0 : theme.spacing(2)}}>© 2024 Stratogen. All Rights Reserved.</Typography>
              <div>
                <a target={'_blank'} href={'https://t.me/stratogenchat'} rel="noreferrer" >
                  <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8)}}>
                    <TelegramIcon/>
                  </IconButton>
                </a>
                <a target={'_blank'} href={'https://twitter.com/StratogenTech'} rel="noreferrer" >
                  <IconButton size='small' className='svg_icons' style={{...iconButtonStyle, padding: theme.spacing(1.35), marginRight: 0}}>
                    <XIcon/>
                  </IconButton>
                </a>
              </div>
            </div>
          </Container>
      </Box>
    </Box>
  );
}

export default Index;

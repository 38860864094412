import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, IconButton, Drawer, Button, Toolbar, Box, AppBar } from '@mui/material';
import { useTheme } from '@mui/material';
import './index.css';
import {useBreakpoints} from '../../../utils/breakpoints';
import {scrollToComponent} from '../../../utils/usefull';

function Index() {
  const theme = useTheme();
  const { isMdUp } = useBreakpoints();
  const [open, setOpen] = React.useState(false);

  const iconButtonStyle = {backgroundColor: theme.palette.primary.contrastText, color: 'white', marginRight: theme.spacing(1)};
  const buttonStyle = {color: 'white', textTransform: 'none', fontSize: theme.spacing(2), padding: theme.spacing(0, 2.5, 0, 2.5)};
  const navbarButtonStyle = {...buttonStyle, marginBottom: theme.spacing(2), width: theme.spacing(15)};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{backgroundColor: theme.palette.primary.light, height: '100%', padding: theme.spacing(5), display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} className="App-logo" alt="logo" height={theme.spacing(4)} style={{marginBottom: theme.spacing(2)}}/>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <a target={'_blank'} rel="noreferrer"  href={'https://docs.stratogen.tech/'}>
              <Button sx={buttonStyle}>Whitepaper</Button>
            </a>
            <Button sx={buttonStyle} onClick={() => scrollToComponent('games')}>Games</Button>
            <Button sx={navbarButtonStyle}>Exchange</Button>
          </div>
          <Button color={'secondary'} variant="contained" sx={{textTransform: 'none', borderRadius: theme.spacing(1), fontWeight: '600'}}>Buy SGEN</Button>
          <a target={'_blank'} rel="noreferrer"  href={'https://t.me/stratogenchat'}>
            <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), width: theme.spacing(4.5), marginTop: theme.spacing(2), marginRight: 0}}>
              <TelegramIcon/>
            </IconButton>
          </a>
          <a target={'_blank'} rel="noreferrer"  href={'https://twitter.com/StratogenTech'}>
            <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8), width: theme.spacing(4.5), marginTop: theme.spacing(1), marginRight: 0}}>
              <XIcon/>
            </IconButton>
          </a>
        </Box>
      </Drawer>
      <AppBar position="static" style={{backgroundColor: '#1e1e1f'}}>
        <Toolbar>
          <Container maxWidth={'xl'} style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} className="App-logo" alt="logo" height={theme.spacing(4)}/>
            <div style={{display: isMdUp ? 'block' : 'none'}}>
                <a target={'_blank'} rel="noreferrer"  href={'https://docs.stratogen.tech/'}>
                  <Button sx={buttonStyle}>Whitepaper</Button>
                </a>
                <Button sx={buttonStyle} onClick={() => scrollToComponent('games')}>Games</Button>
                <a href={'https://raydium.io/'} rel="noreferrer"  target={'_blank'}>
                  <Button sx={buttonStyle}>Exchange</Button>
                </a>
            </div>
            <div style={{display: isMdUp ? 'block' : 'none'}}>
              <a target={'_blank'} rel="noreferrer"  href={'https://t.me/stratogenchat'}>
                <IconButton size='small' style={{...iconButtonStyle, padding: theme.spacing(.8)}}>
                  <TelegramIcon/>
                </IconButton>
              </a>
              <a target={'_blank'} rel="noreferrer" href={'https://twitter.com/StratogenTech'}>
                <IconButton size='small' className='svg_icons' style={{...iconButtonStyle, padding: theme.spacing(1.35)}}>
                  <XIcon/>
                </IconButton>
              </a>
              <Button color={'secondary'} variant="contained" sx={{textTransform: 'none', borderRadius: theme.spacing(1), fontWeight: '600'}}>Buy SGEN</Button>
            </div>
            <div style={{display: isMdUp ? 'none' : 'block'}}>
              <IconButton
                size="large"
                edge="start"
                color="info"
                aria-label="menu"
                sx={{marginRight: theme.spacing(-2)}}
                onClick={() => setOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Index;
